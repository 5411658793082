import { useInput } from 'react-admin';
import { useCallback, useRef } from 'react';
import { MDXEditor, BlockTypeSelect, quotePlugin, tablePlugin, headingsPlugin, UndoRedo, BoldItalicUnderlineToggles, CodeToggle, ListsToggle, listsPlugin, toolbarPlugin, InsertTable, diffSourcePlugin, DiffSourceToggleWrapper } from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import './mdxeditor.css'

const CustomMDXEditor = ({
    minHeight = '200px',
    maxHeight = '600px',
    ...props
  }) => {

    function preprocessMarkdown(markdown:string) {
      return markdown.replace(/(?<!\\)<=/g, '\\<=');
    }

    const handleError = (payload:any) => {
        console.error('Markdown Parsing Error:', payload);        
      };

    const {
        field, // destructuring field from useInput
        fieldState: { invalid, isTouched, isDirty },
        formState: { errors }
    } = useInput(props);

    const { value, onChange } = field;

    const originalValueRef = useRef(value);

    // Memoize the onChange handler
    const handleEditorChange = useCallback(
      (newMarkdown:string) => {
        setTimeout(() => {
          onChange(newMarkdown);
        }, 0);
      },
      [onChange]
    );
  
    return (
        <div style={{marginBottom:'20px'}}>
            <MDXEditor    
                className="custom-mdx-editor" 
                markdown={preprocessMarkdown(value)}            
                onError={handleError}
                onChange={handleEditorChange}
                plugins={[
                    quotePlugin(),
                    diffSourcePlugin({ diffMarkdown: preprocessMarkdown(originalValueRef.current), viewMode: 'rich-text' }),
                    tablePlugin(),
                    listsPlugin(),        
                    headingsPlugin(),            
                    toolbarPlugin({
                        toolbarContents: () => (
                            <>
                            <DiffSourceToggleWrapper>
                                <UndoRedo />
                                <BlockTypeSelect />
                                <BoldItalicUnderlineToggles />
                                <CodeToggle />
                                <ListsToggle options={['bullet']} />
                                <InsertTable />
                              </DiffSourceToggleWrapper>
                            </>
                        )
                    })
                ]}
            />
            {isTouched && invalid && <span>{errors[props.source]?.message}</span>}
        </div>
    );
};

export default CustomMDXEditor;
