import React, { useEffect } from 'react';
import { Layout, LayoutProps, useLocaleState, useAuthProvider } from 'react-admin';
import CustomAppBar from './AppBar';

export default (props: LayoutProps) => {
    const authProvider = useAuthProvider();
    const [currentLocale, setLocale] = useLocaleState();

    // Set the locale from the user's identity
    useEffect(() => {
        if (authProvider && authProvider.getIdentity) {
            authProvider.getIdentity().then(({ locale }) => {
                let dbLocale = locale.replace('-','_');
                if (dbLocale !== currentLocale) {
                    console.log('locale', dbLocale);
                    setLocale(dbLocale || 'en_US');
                }
            });
        }
    }, [authProvider, setLocale]);

    return (
        <Layout {...props} appBar={CustomAppBar} />
    );

}