import { usePermissions, useDataProvider, SelectInput, Create, Edit, SimpleForm, Datagrid, List, TextField, EmailField, TextInput, EditButton, ReferenceInput, PasswordInput, minLength, required, email, AutocompleteInput } from 'react-admin';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getReadableLocaleName } from './i18nProvider';

const userFilters = [
    <ReferenceInput source="customer_id" reference="customers" alwaysOn>
        <SelectInput optionText="name" />
    </ReferenceInput>
];

const RoleInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
  
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const { data } = await dataProvider.custom('users/roles', { method: 'GET' });
                const formattedChoices = data.map((role: string) => ({ id: role, name: role }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        fetchRoles();
    }, [dataProvider]);

    return <SelectInput source="role" choices={choices} label="role" isRequired />;
};

const LocaleInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const [defaultValue, setDefaultValue] = useState('en-US'); // Stel standaardwaarde in

    useEffect(() => {
        const fetchLocales = async () => {
            try {
                const { data } = await dataProvider.custom('users/locales', { method: 'GET' });
                const formattedChoices = data.map((locale: string) => ({
                    id: locale,
                    name: getReadableLocaleName(locale)
                }));
                setChoices(formattedChoices);

                // Optioneel: Update de standaardwaarde indien nodig
                if (!formattedChoices.some((choice: any) => choice.id === defaultValue)) {
                    setDefaultValue(formattedChoices[0]?.id);
                }
            } catch (error) {
                console.error('Error fetching locales:', error);
            }
        };

        fetchLocales();
    }, [dataProvider]);

    return (
        <SelectInput
            source="locale"
            choices={choices}
            label="resources.generic.locale"
            isRequired
            defaultValue={defaultValue} // Gebruik de standaardwaarde hier
        />
    );
};

export const UserList = () => {
    const { customer_id } = useParams();

    return (
    <List exporter={false} resource='users' filters={userFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <EmailField source="email" />
            <TextField source="role" /> 
        </Datagrid>
    </List>
    );

}

export const UserEdit = () => {
    const { permissions } = usePermissions();

    return (
    <Edit title="resources.users.edit" resource='users'>
        <SimpleForm>
            <TextInput source="first_name" validate={required()} />
            <TextInput source="last_name" validate={required()} />
            <TextInput source="email" type="email" validate={[required(),email()]} />
            <PasswordInput source="new_password" validate={[minLength(8)]} autoComplete='no' />
            <LocaleInput />
            {permissions === 'admin' && <RoleInput /> }
        </SimpleForm>
    </Edit>
    );
}

const equalToPassword = (value: any, allValues: any) => {
    if (value !== allValues.password) {
        return 'The two passwords must match';
    }
}

export const UserCreate = () => {
    const { permissions } = usePermissions();

    return (
    <Create resource='users'>
        <SimpleForm>
            <TextInput source="email" type="email"  validate={[required(),email()]} />
            <TextInput source="first_name" validate={required()} />
            <TextInput source="last_name" validate={required()} />
            <ReferenceInput source="customer_id" reference="customers" alwaysOn>
                <AutocompleteInput optionText="name" validate={required()} filterToQuery={(searchText: any) => ({ name: `%${searchText}%` })} />
            </ReferenceInput>
            <LocaleInput />
            {permissions === 'admin' && <RoleInput /> }
            <PasswordInput source="password" validate={required()} />
            <PasswordInput source="confirm_password" validate={[equalToPassword,required()]} />
        </SimpleForm>
    </Create>
    );
}

