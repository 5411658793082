import React, { useEffect, useState } from 'react';
import { AutocompleteInput, useRecordContext, BooleanInput, TabbedForm, TabbedFormTabs, CheckboxGroupInput, AutocompleteArrayInput, useTranslate, usePermissions, SelectInput, Create, Edit, ReferenceField, ChipField, Datagrid, List, TextField, TextInput, useDataProvider, ReferenceInput, FormDataConsumer, ImageField, ImageInput, number } from 'react-admin';
import { MarkdownInput, MarkdownInputProps } from '@react-admin/ra-markdown';
import { JsonInput } from "react-admin-json-view"
import { Chip, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CustomMDXEditor from './CustomMdxEditor';

// import ConversationFlow from './ConversationFlow';

import Typography from '@mui/material/Typography';
import CustomCloneButton from './CustomCloneButton';

import {
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';

import TryAssistantButton from './TryAssistantButton';
import { Box, Grid } from '@mui/material';
import { FieldHeader } from './FieldHeader';

const CustomToolbar = ({isEdit = false}) => {
    const { permissions } = usePermissions();
    return (
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
                {permissions !== 'demo' && <SaveButton label="resources.generic.save_and_close"/>}
                {permissions !== 'demo' && isEdit && <SaveButton 
                label="resources.generic.save"
                mutationOptions={{
                    onSuccess: () => {
                    }}
                }
                type="button"
                variant="text"
                />}
            </Box>
            <Box sx={{ display: 'flex', gap: '16px' }}> {/* Voeg hier de gap eigenschap toe */}
                {permissions === 'admin' && isEdit && <CustomCloneButton excludeFields={['id', 'notification_user', 'caller_id', 'fallback_number', 'stripe_subscription']} variant="text" />}
                {permissions === 'admin' && <DeleteButton mutationMode="pessimistic" />}
            </Box>
        </Toolbar>
    );
}

const validatePhone = (value: string) => {
    // RegEx voor E.164 formaat
    const phoneRegex = /^\+[1-9]\d{1,14}$/;

    // Lege waarde is toegestaan
    if (!value) {
        return undefined;
    }

    // Test of de waarde voldoet aan het E.164 formaat
    if (!phoneRegex.test(value)) {
        // Retourneer een foutmelding als het niet voldoet
        return 'resources.assistants.errors.invalid_phone';
    }

    // Als alles in orde is, retourneer undefined (geen fout)
    return undefined;
};

const postFilters = [
    <TextInput source="name" alwaysOn />,
];


const LanguageInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const translate = useTranslate();

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const { data } = await dataProvider.custom('assistants/languages', { method: 'GET' });
                const formattedChoices = data.map((language: any) => {
                    // Vervang koppelteken door underscore
                    const languageKey = language.replace('-', '_');
                    // Gebruik de aangepaste taalcode om de vertaling op te halen
                    const translatedName = translate('resources.languages.'+languageKey);
                    return { id: language, name: translatedName };
                });
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        fetchLanguages();
    }, [dataProvider, translate]);

    return (
        <>
                <FieldHeader title="resources.assistants.fields.default_language" description="resources.assistants.descriptions.default_language" />
                <AutocompleteInput
                    source="default_language"
                    label=" "
                    choices={choices}
                    fullWidth
                    isRequired
                />

                <FieldHeader title="resources.assistants.fields.additional_languages" description="resources.assistants.descriptions.additional_languages" />
                <FormDataConsumer>
                    {({ formData }) => (
                        <AutocompleteArrayInput
                            source="additional_languages"
                            label=" "
                            choices={choices.filter(choice => choice.id !== formData.default_language)}
                            fullWidth
                        />
                    )}
                </FormDataConsumer>
    </>
    );
};

const TimezoneInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const translate = useTranslate();

    useEffect(() => {
        const fetchTimezones = async () => {
            try {
                const { data } = await dataProvider.custom('assistants/timezones', { method: 'GET' });
                const formattedChoices = data.map((timezone: string) => ({ id: timezone, name: timezone }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching timezones:', error);
            }
        };

        fetchTimezones();
    }, [dataProvider]);

    return <AutocompleteInput source="timezone" choices={choices} label='resources.assistants.fields.timezone' fullWidth isRequired />;
};

const DeploymentInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const translate = useTranslate();
    const record = useRecordContext();

    const optionRenderer = (choice: any) => {
        return (
            <span>
                <span><Chip label={choice.vendor} size="small" color='default' /> </span>            
                <Typography component="span" variant="body2">{(choice.friendly_name)?choice.friendly_name:choice.model}</Typography>
            </span>
        )
    }

    useEffect(() => {
        const fetchAiModels = async () => {
            try {
                const { data } = await dataProvider.custom('assistants/aideployments'+(record.id?'?assistant_id='+record.id:''), { method: 'GET' });
                const formattedChoices = data.map((deployment: any) => ({
                    id: deployment.id,
                    vendor: deployment.vendor,
                    model: deployment.model,
                    friendly_name: deployment.friendly_name
                }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching deployments:', error);
            }
        };

        fetchAiModels();
    }, [dataProvider]);

    return (
        <SelectInput
            source="ai_deployment_id"
            choices={choices}
            optionText={optionRenderer}
            label={translate('resources.assistants.fields.ai_model')}
            fullWidth
            isRequired
        />
    );
};


export const AssistantList = () => {
    const { permissions } = usePermissions();

    return (
            <List filters={postFilters} hasCreate={permissions === 'admin'}  exporter={false}>
                <Datagrid rowClick="edit" bulkActionButtons={false}>`
                    <TextField source="id" />
                    <TextField source="name" />
                    <ReferenceField reference="users" source="notification_user">
                            <ChipField source="first_name" />
                    </ReferenceField>
                    <TextField source="caller_id" reference="callers" />
                    <ReferenceField source="customer_id" reference="customers"><TextField source="name" /></ReferenceField>        
                    <TryAssistantButton />
                </Datagrid>
            </List>
        );
}

// Globale audio variabele
let audio: HTMLAudioElement | null = null;

const PlayAudioButton = ({ endpoint, params }: { endpoint: string, params: any }) => {
    // State toevoegen voor het bijhouden van de afspeelstatus
    const [isPlaying, setIsPlaying] = useState(false);
    const location = useLocation(); // Haal de huidige locatie op met React Router

    const playAudio = async () => {
      const apiUrl = import.meta.env.VITE_API_URL;
      const fullUrl = apiUrl + endpoint;
      const auth = localStorage.getItem('auth');
      const token = auth ? JSON.parse(auth).access_token : null;
  
      try {
        // Stop de huidige audio indien deze speelt en wijzig de afspeelstatus
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          setIsPlaying(false);
        }
  
        const response = await fetch(fullUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(params)
        });
        const audioBlob = await response.blob();
        const audioUrl = URL.createObjectURL(audioBlob);
        audio = new Audio(audioUrl);
        audio.play();
        setIsPlaying(true); // Update de afspeelstatus
  
        // Voeg een event listener toe om de state bij te werken als de audio eindigt
        audio.addEventListener('ended', () => setIsPlaying(false));
      } catch (error) {
        console.error('Fout bij het afspelen van audio:', error);
      }
    };
  
    const stopAudio = () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
        setIsPlaying(false); // Update de afspeelstatus
      }
    };

    useEffect(() => {
        // Stop de audio wanneer de locatie verandert (d.w.z. de gebruiker navigeert weg)
        return () => {
          if (audio) {
            audio.pause();
            audio.currentTime = 0;
            setIsPlaying(false);
          }
        };
      }, [location]);    
  
    // Wijzig de onClick handler en het icoon op basis van de afspeelstatus
    return (
      <IconButton onClick={isPlaying ? stopAudio : playAudio} color="primary" size="large">
        {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
      </IconButton>
    );
  };
  

interface CommonFormProps {
    isEdit: boolean;
    defaultValues?: any; // Je kunt hier een specifieker type definiëren afhankelijk van de structuur van je defaultValues.
    customToolbar?: React.ReactNode;
  }

 
const CommonForm: React.FC<CommonFormProps> = ({ isEdit, defaultValues }) =>  {
    const { permissions } = usePermissions(); 
    const record = useRecordContext();
    const translate = useTranslate();
    const [text, setText] = useState('');
    const [defaultLanguage, setDefaultLanguage] = useState('');
    const [assistantId, setAssistantId] = useState('');


    // temporarily show banner informing user to update their prompt components
    const [isBannerVisible, setIsBannerVisible] = useState(true);
    const showBanner = isBannerVisible && record && record.id < 64 && ( (record.prompt_scenarios && !record.prompt_scenarios.role) || !record.prompt_scenarios);

    return (
        <>
            {showBanner && (
  <Alert 
    severity="info"
    action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setIsBannerVisible(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
    }
    
>
    {translate('resources.assistants.banner_prompt_components_line1')}
    <br /><br />    
    <strong>{translate('resources.assistants.banner_prompt_components_note')}</strong>
    {translate('resources.assistants.banner_prompt_components_line2')}
  </Alert>
)}

            <TabbedForm warnWhenUnsavedChanges defaultValues={defaultValues} toolbar={<CustomToolbar isEdit={isEdit} />} syncWithLocation={false} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}>
                <TabbedForm.Tab label="resources.assistants.tabs.business_information">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item xs={12} md={8} alignItems="top">
                            <FieldHeader title="resources.assistants.fields.name" description="resources.assistants.descriptions.name" /> 
                            <TextInput source="name" fullWidth label="" />
                            
                            <FieldHeader title="resources.assistants.fields.prompt_scenarios.business_information" description="resources.assistants.descriptions.prompt_scenarios.business_information" />
                            <TextInput source="prompt_scenarios.about_business" fullWidth multiline className="custom-multiline" label="" />     

                            <FieldHeader title="resources.assistants.fields.timezone" description="resources.assistants.descriptions.timezone" />
                            <TimezoneInput />     
                            <FieldHeader title="resources.assistants.fields.whatsapp_image" description="resources.assistants.descriptions.whatsapp_image" />
                            <Grid item xs={6}>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {                                        
                                        return (
                                            <ImageInput 
                                                source="config.whatsapp_image" 
                                                label=" "
                                                options={{disabled:formData.config && formData.config.whatsapp_image && formData.config.whatsapp_image.src}}
                                                placeholder={formData.config && formData.config.whatsapp_image && formData.config.whatsapp_image.src ? <></> : null}
                                            >
                                                <ImageField source="src" title="title" sx={{ '& .RaImageField-image': { minWidth: '400px', maxWidth: '500px',  height: 'auto' } }}/>
                                            </ImageInput>
                                        );
                                    }}
                                </FormDataConsumer>
                            </Grid>                                                                        
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>

                <TabbedForm.Tab label="resources.assistants.tabs.personality">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item xs={12} md={8}>

                            <FieldHeader title="resources.assistants.fields.prompt_scenarios.role" description="resources.assistants.descriptions.prompt_scenarios.role" />                                       
                            <TextInput source="prompt_scenarios.role" fullWidth multiline className="custom-multiline" label="" />


                            <Grid container spacing={1} alignItems="top">
                                <Grid item xs={11}>
                                    <FieldHeader title="resources.assistants.fields.greeting" description="resources.assistants.descriptions.greeting" />
                                </Grid>
                                <Grid item xs={1}>
                                    <FormDataConsumer>
                                    {({ formData, ...rest }) => (
                                            <PlayAudioButton
                                                endpoint="/assistants/play/audio" 
                                                params={{
                                                    text: formData.greeting,
                                                    language: formData.default_language,
                                                    id: formData.id
                                                }}
                                            />
                                        )}
                                        </FormDataConsumer>    
                                    </Grid>
                                    <TextInput source="greeting" fullWidth multiline className="custom-multiline" label="" />
                                </Grid>    

                            <LanguageInput />  

                        </Grid>
                    </Grid>        

                    </TabbedForm.Tab>


                    <TabbedForm.Tab label="resources.assistants.tabs.tasks">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item xs={12} md={8}>
                                <FieldHeader title="resources.assistants.fields.prompt" description="resources.assistants.descriptions.prompt" />
                                <CustomMDXEditor source="prompt" />

                                <FieldHeader title="resources.assistants.fields.post_call_prompt" description="resources.assistants.fields.post_call_prompt_help_text" />
                                <CustomMDXEditor source="post_call_prompt" />
                            </Grid>
                        </Grid>
                    </TabbedForm.Tab>

                    <TabbedForm.Tab label="resources.assistants.tabs.policies_and_context">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item xs={12} md={8}>
                                <FieldHeader title="resources.assistants.fields.prompt_scenarios.faq" description="resources.assistants.descriptions.prompt_scenarios.faq" />
                                <CustomMDXEditor source="prompt_scenarios.faq" />

                                <FieldHeader title="resources.assistants.fields.prompt_scenarios.transfer_policy" description="resources.assistants.descriptions.prompt_scenarios.transfer_policy" />                                
                                <CustomMDXEditor source="prompt_scenarios.transfer_policy" />


                                <FieldHeader title="resources.assistants.fields.prompt_scenarios.call_policy" description="resources.assistants.descriptions.prompt_scenarios.call_policy" />
                                <CustomMDXEditor source="prompt_scenarios.call_policy" />

                            </Grid>
                        </Grid>
                    </TabbedForm.Tab>

                <TabbedForm.Tab label="resources.assistants.tabs.notifications">
                    <Grid container spacing={2}> 
                        <Grid item xs={12} md={6}> 
                            <FieldHeader title="resources.assistants.fields.notification_outcomes" description="resources.assistants.descriptions.notification_outcomes" />
                            <CheckboxGroupInput
                                        defaultValue={[]}
                                        label=""
                                        source="notification_outcomes"                            
                                        choices={[        
                                            { id: 'business_follow_up', name: 'resources.conversations.outcomes.business_follow_up' },
                                            { id: 'caller_follow_up', name: 'resources.conversations.outcomes.caller_follow_up' },
                                            { id: 'transferred', name: 'resources.conversations.outcomes.transferred' },
                                            { id: 'information_provided', name: 'resources.conversations.outcomes.information_provided' },
                                            { id: 'completed', name: 'resources.conversations.outcomes.completed' },
                                            { id: 'no_action', name: 'resources.conversations.outcomes.no_action' },
                                        ]}
                                        row={false}
                                        format={value => Array.isArray(value) ? value : []} 
                                        /> 

                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.notification_outcomes && formData.notification_outcomes.length > 0 && (
                                    <>
                                    <FieldHeader title="resources.assistants.fields.notification_user" description="resources.assistants.descriptions.notification_user" />                                        
                                    <ReferenceInput source="notification_user" reference="users">
                                        <AutocompleteInput 
                                            fullWidth 
                                            optionText={(choice) => `${choice.first_name} ${choice.last_name} (${choice.email})`} 
                                            filterToQuery={(searchText: any) => ({ 
                                                email: `%${searchText}%`, 
                                                //first_name: `%${searchText}%`, #todo allow search for OR
                                                //last_name: `%${searchText}%` 
                                            })} 
                                        />                                            
                                    </ReferenceInput>                        
                                    </>
                                    )}
                            </FormDataConsumer> 

                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    <>
                                        <FieldHeader 
                                            title="resources.assistants.fields.config.notifications.include_transcript" 
                                            description="resources.assistants.descriptions.config.notifications.include_transcript" 
                                        />
                                        <BooleanInput 
                                            source="config.notifications.include_transcript" 
                                            label={formData.config?.notifications?.include_transcript ? 'ra.boolean.true' : 'ra.boolean.false'} 
                                            {...rest} 
                                        />
                                    </>
                                )}
                            </FormDataConsumer>

                        </Grid> 
                        </Grid>
                </TabbedForm.Tab>

                {record && record.config && record.config.tools && record.config.tools.formitable &&
                <TabbedForm.Tab label="resources.assistants.tabs.tools">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item xs={12} md={8}>
                            <FieldHeader title="resources.assistants.fields.config.tools.formitable.config.restaurant_id" description="resources.assistants.descriptions.config.tools.formitable.config.restaurant_id" />
                            <TextInput source="config.tools.formitable.config.restaurant_id" fullWidth />

                            <FieldHeader title="resources.assistants.fields.config.tools.formitable.config.api_key" description="resources.assistants.descriptions.config.tools.formitable.config.api_key" />
                            <TextInput source="config.tools.formitable.config.api_key" fullWidth />
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                }

                {permissions === 'admin' && 
                <TabbedForm.Tab label="resources.assistants.tabs.admin">
                    <Grid container spacing={2}> 
                        <Grid item xs={12} md={6}> 

                            <FieldHeader title="resources.assistants.fields.deployment" description="resources.assistants.descriptions.deployment" />
                            <DeploymentInput />
                            <FieldHeader title="resources.assistants.fields.caller_id" description="resources.assistants.descriptions.caller_id" />
                            <TextInput source="caller_id" fullWidth validate={validatePhone} />
                            <FieldHeader title="resources.assistants.fields.fallback_number" description="resources.assistants.descriptions.fallback_number" />
                            <TextInput source="fallback_number" fullWidth validate={validatePhone} />
                            <FieldHeader title="resources.assistants.fields.customer_id" description="resources.assistants.descriptions.customer_id" />
                            <ReferenceInput source="customer_id" reference="customers">
                                <AutocompleteInput optionText="name" fullWidth filterToQuery={(searchText: any) => ({ name: `%${searchText}%` })} />
                            </ReferenceInput>

                            <FieldHeader title="resources.assistants.fields.prompt_scenarios.context" description="resources.assistants.descriptions.prompt_scenarios.context" />
                            <CustomMDXEditor source="prompt_scenarios.context" />

                            <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <>
                                                <FieldHeader 
                                                    title="resources.assistants.fields.config.stt.interruption" 
                                                    description="resources.assistants.descriptions.config.stt.interruption" 
                                                />
                                                <BooleanInput 
                                                    source="config.stt.interruption" 
                                                    label={formData.config?.stt?.interruption ? 'ra.boolean.true' : 'ra.boolean.false'} 
                                                    {...rest} 
                                                />
                                            </>
                                        )}
                            </FormDataConsumer>

                            <FieldHeader title="resources.assistants.fields.config.stt.backend" description="resources.assistants.descriptions.config.stt.backend" />
                            <SelectInput
                                source="config.stt.backend"
                                choices={[
                                    { id: 'azure', name: 'Microsoft Azure' },
                                    { id: 'deepgram', name: 'Deepgram' },
                                ]}
                                label=""
                                emptyText="Default"
                                emptyValue=""
                            />                    

                            <FieldHeader title="resources.assistants.fields.config" description="resources.assistants.descriptions.config" />
                            <JsonInput
                                source="config"                                
                                reactJsonOptions={{
                                    // Props passed to react-json-view
                                    name: null,
                                    collapsed: false,
                                    enableClipboard: true,
                                    displayDataTypes: false,
                                }}
                            />    
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                }
                
            </TabbedForm>
            </>
    );
  }
// Aangepaste titel component voor AssistantEdit
const AssistantTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return record ? (
        <>{translate('resources.assistants.edit')}: {record.name}</>
    ) : null;
};



export const AssistantEdit = () => {
    return (
        <Edit title={<AssistantTitle />}  mutationMode='pessimistic' >
            <CommonForm isEdit={true} />
        </Edit>
    );
}


export const AssistantCreate = () => {

    const [defaultValues, setDefaultValues] = useState({});
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchDefaultValues = async () => {
            try {
                const { data } = await dataProvider.getDefaults('assistants');
                setDefaultValues(data);
            } catch (error) {
                console.error('Error fetching default values', error);
            }
        };

        fetchDefaultValues();        
    }, [dataProvider]);


    return (
        <Create>
            <CommonForm isEdit={false} defaultValues={defaultValues} />
        </Create>
    )

}
