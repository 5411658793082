import { Create, Edit, SimpleForm, Datagrid, List, TextField, TextInput } from 'react-admin';
import { MarkdownInput, MarkdownInputProps } from '@react-admin/ra-markdown';
import CustomMDXEditor from './CustomMdxEditor';


export const PromptTemplateList = () => (
    <List exporter={false}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
        </Datagrid>
    </List>
    );

export const PromptTemplateEdit = () => {
    
    return (
        <Edit mutationMode='optimistic'>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" fullWidth />
                <CustomMDXEditor source="content" />

            </SimpleForm>
        </Edit>
    );
}

export const PromptTemplateCreate = () => {
    const options: Partial<MarkdownInputProps> = {
        previewStyle: "tab",        
        initialEditType: "wysiwyg",
        useCommandShortcut: true,
        height: '600px',
        toolbarItems: [
            ['heading', 'bold'/*, 'italic', 'strike'*/],
            ['hr', 'quote'],
            ['ul', 'ol', 'task', 'indent', 'outdent'],
            ['table'/*, 'image', 'link'*/],
            ['code', 'codeblock'],
            ['scrollSync'],
        ],        
    };
    return (
        <Create>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" fullWidth />
                <MarkdownInput source="content" fullWidth {...options} />
            </SimpleForm>
        </Create>
    );
}


