import React, { useRef, useState, useEffect } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, TextField, Button, Paper, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { useAuthenticated } from 'react-admin';


const ChatComponent = () => {
  useAuthenticated(); 
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const dataProvider = useDataProvider();
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    startConversation();
  }, []); // Afhankelijkhedenlijst is leeg om dit alleen bij de eerste render te laten uitvoeren
  
  useEffect(() => {
    scrollToBottom(); // Zorg ervoor dat dit gebeurt na het laden van berichten of toevoegen van nieuwe berichten
  }, [messages]); // Voeg 'messages' toe als afhankelijkheid zodat het scrollt wanneer 'messages' verandert
  
  const roleColors = {
    'assistant': 'grey.100',
    'user': 'white',
    'tool': '#b8ffcd',
    // Voeg hier meer rollen en kleuren toe
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };  

  const startConversation = async () => {
    console.info('runnig');
    try {
      const { data } = await dataProvider.custom('assistants/try', {
        data: { 
          assistant_id: id,
          messages_length: 0,
        },        
      });
      //const { test_conversation_id, message } = data;
      const { test_conversation_id, messages: newMessages } = data; // Veronderstel dat 'messages' een array is

      console.log('Starting conversation with id:', test_conversation_id)
      setConversationId(test_conversation_id);

      newMessages.forEach(newMessage => {
        setMessages(messages => [...messages, newMessage ]);
      });

      //setMessages([{ role: 'assistant', content: message }]);      
    } catch (error) {
      console.error('Error starting the conversation:', error);
    }
  };

  const sendMessage = async () => {
    setLoading(true); // Begin het laden zodra de aanvraag start
    if (input.trim() === '') return;
    const userMessage = input;
    setInput('');
    setMessages(messages => [...messages, { role: 'user', content: userMessage }]);

    try {
      const { data } = await dataProvider.custom('assistants/try', {
        data: {
          assistant_id: id,
          user_message: userMessage,
          test_conversation_id: conversationId,
          messages_length: messages.length,
        },
      });
      //const { message } = data;
      const { messages: newMessages } = data; // Veronderstel dat 'messages' een array is

      //setMessages(messages => [...messages, { role: 'assistant', content: message }]);
      newMessages.forEach(newMessage => {
        setMessages(messages => [...messages, newMessage ]);
      });
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
        setLoading(false); // Stop het laden ongeacht het resultaat
    }
  };
  const translate = useTranslate();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Paper elevation={3} sx={{ overflow: 'auto', flexGrow: 1, display: 'flex', flexDirection: 'column-reverse' }}>
        <List>
          {messages.map((msg, index) => (
            <ListItem 
              key={index} 
              sx={{ bgcolor: roleColors[msg.role] || 'background.default' }}
            >
              <ListItemText
                primary={msg.role}
                secondary={
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                  {msg.tool_calls ? msg.tool_calls[0].function.name+' => '+msg.tool_calls[0].function.arguments : 
                   msg.content}
                </span>
                }
              />
            </ListItem>
          ))}
          <div ref={messagesEndRef} /> {/* Verplaats deze div buiten de map, aan het einde van de List */}
        </List>
      </Paper>
      <Box sx={{ position: 'sticky', bottom: 0, width: '100%', bgcolor: 'background.paper', display: 'flex', alignItems: 'center', gap: 1, padding: 1 }}>
        <TextField
          label={translate('resources.assistants.try.type_your_message')}
          variant="outlined"
          fullWidth
          autoComplete='off'
          value={input}
          onChange={e => setInput(e.target.value)}
          onKeyPress={e => e.key === 'Enter' && sendMessage()}
        />
        <Button variant="contained" color="primary" onClick={sendMessage} disabled={!input.trim()}>
        {translate('resources.assistants.try.send')}
        </Button>
        {loading && <CircularProgress size={24} />}
      </Box>
    </Box>
  );
};

export default ChatComponent;