import { MenuItemLink, useTranslate, TopToolbar, ListButton, Create, Edit, DeleteButton, SimpleForm, Datagrid, List, TextField, EditButton, TextInput, Title } from 'react-admin';
import { Button } from '@mui/material';
import UserIcon from "@mui/icons-material/Group";
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

const LinkToRelatedUsers = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    
    // Handler om de event propagatie te stoppen
    const handleClick = (event: any) => {
        // Voorkomt dat de event verder bubbelt
        event.stopPropagation();
    };
    return record ? (
        <Button
            size="small"
            onClick={handleClick}
            color="primary"
            variant="outlined"
            component={Link}
            startIcon={<UserIcon />}
            to={{
                pathname: '/users',
                search: `filter=${JSON.stringify({ customer_id: record.id })}`,
            }}
        >
            {translate('resources.users.name', { smart_count: 2 })}
        </Button>
    ) : null;
};

const postFilters = [
    <TextInput source="name" alwaysOn />,
];

const CustomerListButton = () => (
    <TopToolbar>    
        <ListButton />
    </TopToolbar>
);


export const CustomerList = () => (
    <List filters={postFilters} exporter={false} >
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="name" />
            <LinkToRelatedUsers />
        </Datagrid>
    </List>
    );

export const CustomerEdit = () => (
    <Edit actions={<CustomerListButton />}>
        <SimpleForm>
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const CustomerCreate = () => (
    <Create actions={<CustomerListButton />}>
        <SimpleForm>
            <TextField source="id"  />
            <TextInput source="name" />
        </SimpleForm>

    </Create>
);


