import { useNavigate, useLocation } from 'react-router-dom'; // of useHistory voor oudere versies

import { AppBar, TitlePortal, UserMenu, useUserMenu, Logout, useTranslate } from 'react-admin';
import { CustomLocalesMenuButton } from './CustomLocaleMenuButton';
import { Box, useMediaQuery, Theme } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Logo from './Logo';

const ConfigurationMenu = () => {
    const translate = useTranslate();
    const { onClose } = useUserMenu();
    const navigate = useNavigate();
    const location = useLocation(); // huidige locatie
    const isActive = location.pathname === '/profile'; // controleer of het pad actief is

    const handleNavigation = () => {
        navigate('/profile');
        onClose();
    };

    return (
        <MenuItem 
            onClick={handleNavigation}
            className={isActive ? 'RaMenuItemLink-active' : ''} // voeg 'active' class toe indien actief
        >
            <ListItemIcon>
                <SettingsIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>
                {translate("resources.generic.my_profile")}
            </ListItemText>
        </MenuItem>
    );
};


const CustomAppBar = (...props: any) => {
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    return (
        <AppBar 
        
        userMenu={
            <UserMenu>         
                <CustomLocalesMenuButton icon={<LanguageIcon fontSize='small' />} />         
                <ConfigurationMenu/>
                <Logout />                    
            </UserMenu>
        }
        toolbar={
            <>
        <TitlePortal flex={0} />
        <Logo />            
        {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}           
        </>
        }>
        </AppBar>
    );
};

export default CustomAppBar;